<template>
  <b-card no-body class="card-custom mb-3">
    <template #header>
      <b-button v-if="user.hasPermission('rtc:widget.create')" class="float-right" size="sm" variant="primary" :to='{name: "widgets.widget.create"}'>
        <BIconPlus/> New Widget
      </b-button>
      <h6 class="mb-0 pt-1">My Widgets</h6>
    </template>
    <b-card-text>
      <div class="table-custom">
        <WidgetList :user="user"/>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {BIconPlus} from 'bootstrap-vue';
import WidgetList from './../../components/Widget/Widget.List';
export default {
    props: ['user'],
    components: {
      BIconPlus,WidgetList
    },

};
</script>
